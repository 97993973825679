
import { defineComponent, reactive, ref, nextTick,onMounted } from 'vue'
import {useStore} from 'vuex'
import type { Moment } from 'moment'
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue'
import { getFirstLevelBU } from '@/utils'
import {
  getLocRegCollectResults as getLocRegCollectResultsAPI,
  exportLocRegCollectResults as exportLocRegCollectResultsAPI
} from '@/API/localRegistration'
import { downloadFromStream } from '@/utils/payment/downloadFile'

interface QueryParams {
  bu: string;
  time: [Moment | undefined, Moment | undefined];
}

export default defineComponent({
  components: {
    BuSelector
  },
  setup() {
    const conditions = reactive<QueryParams>({
      bu: getFirstLevelBU()?.nameEn ?? '',
      time: [undefined, undefined]
    })

    const stat = reactive({
      sucrate: '0.00%',
      total: 0,
      effectivecnt: 0,
      checkcnt: 0,
      failcnt: 0
    })
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0,
    });

    const columns = [
      {
        title: '车架号',
        dataIndex: 'vin'
      },
      {
        title: '车牌号码',
        dataIndex: 'licenseNumber'
      },
      {
        title: '批发来源',
        dataIndex: 'entity'
      },
      {
        title: '品牌/车型',
        dataIndex: 'brand'
      },
      {
        title: '零售类型',
        dataIndex: 'usage'
      },
      {
        title: '零售日期',
        dataIndex: 'retailDate'
      },
      {
        title: '上牌年月',
        dataIndex: 'regMonth'
      },
      {
        title: '上牌省份',
        dataIndex: 'provinceNameCn'
      },
      {
        title: '上牌城市',
        dataIndex: 'cityNameCn'
      },
      {
        title: '检查结果',
        slots: { customRender: 'LocalRegVol' }
      }
    ]

    const data = ref([])

    const handleReset = () => {
      conditions.bu = getFirstLevelBU()?.nameEn ?? ''
      conditions.time = [undefined, undefined]
    }

    const handleExport = async () => {
      const res = await exportLocRegCollectResultsAPI(conditions.bu ?? '',
        conditions.time[0] ? conditions.time[0].format('YYYY-MM-DD') : '',
        conditions.time[1] ? conditions.time[1].format('YYYY-MM-DD') : '',
        isDealer() ? (store.state.user as any).organization.entityCode : '')
      downloadFromStream(res)
    }

    // 刷新表格高度
    const tableHeight = ref('0');
    const getTableScroll = () => {
        nextTick(() => {
            //  默认底部分页50 + 边距10
            const extraHeight = 100;
            const tHeader = document.getElementsByClassName(
                'content-container'
            )[0];
            //表格内容距离顶部的距离
            let tHeaderBottom = 0;
            if (tHeader) {
                tHeaderBottom = tHeader.getBoundingClientRect().top;
            }
            //窗体高度-表格内容顶部的高度-表格内容底部的高度
            const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
            tableHeight.value = height;
        });
    };

    const store = useStore()
    const isDealer = () => {
        return (store.state.user as any).legalEntity.entityCode === "Dealer"
    }

    const handleSearch = async () => {
        const params =  {
            bu:conditions.bu ?? '',
            startTime:conditions.time[0] ? conditions.time[0].format('YYYY-MM-DD') : '',
            endTime:conditions.time[1] ? conditions.time[1].format('YYYY-MM-DD') : '',
            userDealerCode: isDealer() ? (store.state.user as any).organization.entityCode : ''
        }
        const query = {
            current: pagination.currentPage,
            size: pagination.pageSize,
        }
      const res = await getLocRegCollectResultsAPI(params,query)

      data.value = res.page.content
      stat.sucrate = res.sucrate
      stat.total = res.total
      stat.effectivecnt = res.effectivecnt
      stat.checkcnt = res.checkcnt
      stat.failcnt = res.failcnt
      getTableScroll()
      pagination.total = res.page.totalElements;
    }
    const pageChange = (page: number) => {
        pagination.currentPage = page > 0 ? page : 0;
        handleSearch();
    };
    const sizeChange = (page: number, pageSize: number) => {
        pagination.currentPage = page > 0 ? page : 0;
        pagination.pageSize = pageSize;
        handleSearch();
    };

    onMounted(() => [
        handleSearch()
    ])

    return {
      conditions,
      stat,
      columns,
      data,
      handleReset,
      handleSearch,
      handleExport,
      pagination,
      tableHeight,
      pageChange,
      sizeChange,
    }
  }
})
